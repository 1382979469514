
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getTagsList, saveTag, removeTag } from "@/api/request/tag";

//组件
@Component({
  name: "TagsManager",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //------------------------------------ 表格 ------------------------------------
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //请求数据
    name: "", //名称
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTagsList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.form = {
      //临时数据
      sort_str: "",
      rate_str: "",

      //请求数据
      id: 0, //ID
      sort: 0, //排序值，
      rate: 0, //比例
      type_id: 0, //类型：1-描述型，2-技能型
      name_list: {
        zh: "", // 中文，必传
        en: "", // 英文，必传
        id: "", // 印尼语，必传
      },
    };
    this.dialogStatus = "create";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      sort_str: String(row.sort),

      //请求数据
      id: row.id, //ID
      sort: row.sort, //排序值，
      rate: row.rate, //比例
      type_id: row.type_id, //类型：1-描述型，2-技能型
      name_list: {
        zh: row.name_list.zh ? row.name_list.zh : "", // 中文，必传
        en: row.name_list.en ? row.name_list.en : "", // 英文，必传
        id: row.name_list.id ? row.name_list.id : "", // 印尼语，必传
      },
    };
    this.dialogStatus = "updata";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    this.$confirm("确定删除标签<" + row.name + ">？", "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求删除
        const res: any = await removeTag({ id: row.id });

        //删除成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("删除成功");

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //---------------------------------- 创建/编辑 ----------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private dialogTitle: any = { updata: "编辑标签", create: "创建标签" };

  //列表请求参数
  private form: any = {
    //临时数据
    sort_str: "",

    //请求数据
    id: 0, //ID
    sort: 99, //排序值，
    rate: 0.7, //比例
    type_id: 0, //类型：1-描述型，2-技能型
    name_list: {
      zh: "", // 中文，必传
      en: "", // 英文，必传
      id: "", // 印尼语，必传
    },
  };

  //类型列表
  private typeList: any[] = [
    { id: 0, name: "请选择类型" },
    { id: 1, name: "描述型" },
    { id: 2, name: "技能型" },
  ];

  //处理保存
  private async btneSave() {
    //判断返回
    if (this.form.rate == 0) return this.$message.error("比例 > 0");
    if (this.form.type_id == 0) return this.$message.error("请选择类型");
    if (Number(this.form.sort_str) == 0) return this.$message.error("排序 > 0");
    if (this.form.name_list.zh == "") return this.$message.error("请输入名称(中文)");
    if (this.form.name_list.en == "") return this.$message.error("请输入名称(英文)");
    if (this.form.name_list.id == "") return this.$message.error("请输入名称(印尼语)");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.name = this.form.name_list.zh;
    this.form.sort = Number(this.form.sort_str);

    //编辑礼包
    const res = await saveTag(this.form);

    //显示提示
    this.$message.success(this.isCreate ? "创建成功" : "编辑成功");

    //隐藏界面
    this.dialogVisible = false;
    this.dialogLoading = false;

    //获取数据
    this.getList();
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //是否创建
  private get isCreate(): boolean {
    return this.dialogStatus === "create";
  }
}
